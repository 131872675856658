<template>
  <div class="col">
    <card style="margin: auto" class="justify-center p-4">
      <!-- lci instructions coming from CMS  -->
      <div v-if="get_lci_instructions" v-html="get_lci_instructions"></div>
      <div style="display: table; margin: 0 auto" class="">
        <router-link to="/edit-questionnaire/" class="card-category">
          <l-button type="default" wide default outline class="mt-4">
            {{ localization("Go To LCI") }}
            <span class="btn-label btn-label-right ml-2">
              <i class="fa fa-arrow-right"></i>
            </span>
          </l-button>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  data: () => ({
    one: 0,
    result: [],
    answersall: {}
  }),
  computed: {
    get_lci_instructions() {
      // get lci instructions from vuex
      return this.$store.getters["user/get_lci_instructions"]
        ? this.$store.getters["user/get_lci_instructions"][0].body
        : "";
    }
  },
  mounted() {
    // send request to database to get lci instructions
    this.$store.dispatch("user/getLciInstructions");
  }
};
</script>
<style scoped>
li {
  line-height: 2.8;
  font-size: 15px;
}
</style>
